import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import axios from "axios";
import swal from "sweetalert";
import FormInput from "./FormInput";
import SuccessData from "./SuccessData";

export const VerificationPage = (props) => {
  const [barcode, setBarcode] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(9999999999999);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const [barError, setBarError] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [barMessage, setBarMessage] = useState("");

  function handleSubmitData(e) {
    e.preventDefault();
    if (barcode == "") {
      setBarError(true);
    }
    if (pinCode == "") {
      setPinError(true);
    }
    if(
      phoneNumber.toString().length != 11 ||
      phoneNumber.toString().startsWith("01") == false
    ){
      setPhoneError(true);
    }
    if (barcode != "" && pinCode != "" &&  phoneNumber.toString().length === 11 ) {
      // alert(barcode + "  " + pinCode + "   " + phoneNumber);
      props.setActive(true);

      axios({
        method: "post",
        url: "https://waltontvrni.com/registration/api/registration/validate",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          barcode: barcode,
          mobile: phoneNumber,
          pin: pinCode,
        }),
      })
        .then((response) => {
          if (
            response.data.data != undefined &&
            response.data.data.data != null
          ) {
            setData(response.data.data);
            setSuccess(true);
          } else {
            props.setActive(false);
            console.log(JSON.stringify(response.data));
            swal("দুঃখিত!", "আপনার প্রদানকৃত তথ্য সঠিক নয়। ", "warning");
          }
          props.setActive(false);
        })
        .catch((error) => {
          props.setActive(false);
          console.log(error);
          swal(
            "দুঃখিত!",
            "এই মূহুর্তে আপনার আবেদনটি প্রসেস করা সম্ভব হচ্ছে না। ",
            "error"
          );
        });
    } else {
      props.setActive(false);
      if (barcode == "" && pinCode == "")
        swal(
          "দুঃখিত!",
          "অনুগ্রহপূর্বক বারকোড ও পিনকোড প্রদান করুন ",
          "warning"
        );
      if (barcode == "" && pinCode != "")
        swal("দুঃখিত!", "অনুগ্রহপূর্বক বারকোড প্রদান করুন ", "warning");
      if (barcode != "" && pinCode == "")
        swal("দুঃখিত!", "অনুগ্রহপূর্বক পিনকোড প্রদান করুন ", "warning");
    }
  }
  const handleBarCode = (event) => {
    setBarcode(event.target.value.toUpperCase());
  };
  const handlePinCode = (event) => {
    if (event.target.value.length <= 3) {
      //alert(event.target.value);
      const validatedValue = event.target.value.replace(/[^0-9]/g, "");
      setPinCode(validatedValue);
    } else {
      event.target.value = event.target.value.slice(0, 3);
    }
  };

  const handlePhoneNumber = (event) => {
    const validatedValue = event.target.value.replace(/[^0-9]/g, "");
    setPhoneNumber(validatedValue);
  };

  return (
    <div style={{ height: "100vh" }}>
      <section class="wrapper">
        <div class="container">
          <div class="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 text-center">
            {/* <div class="logo">
              <img
                src="./img/favicon.af9617d1.png"
                class="img-fluid py-2"
                alt="logo"
              />
            </div> */}
            <h3 class="text-dark fw-bolder fs-1 m-4">
              ওয়ালটন এবং মার্সেল টিভি রেজিস্ট্রেশন
            </h3>
            {success == false ? (
              <FormInput
                handleBarCode={handleBarCode}
                handlePinCode={handlePinCode}
                handlePhoneNumber={handlePhoneNumber}
                handleSubmitData={handleSubmitData}
                pinError={pinError}
                barError={barError}
                phoneError={phoneError}
                loading={props.setActive}
                active={props.active}
                pinCode={pinCode}
                barCode={barcode}
                barMessage={barMessage}
              />
            ) : (
              <SuccessData data={data} />
            )}
          </div>
        </div>
      </section>
      <footer class="py-3 my-4">
        <p class="text-center text-muted">
          Copyright © {new Date().getFullYear()} and all rights reserved by -
          All Sister Concerns of WALTON Group
        </p>
      </footer>
    </div>
  );
};
