import React, { useState, useEffect } from "react";

const SuccessData = (props) => {
  //   useEffect(() => {
  //     alert(JSON.stringify(props.data));
  //   });
  if (props.data.status == 1) {
    return (
      <>
        <div className="col-lg-12 col-md-12">
          <div className="card border-0 shadow rounded-lg py-4 text-start">
            <div className="card-body p-5">
              <h3 className="h3  text-success">
                আপনার টিভির রেজিস্ট্রেশন সফল হয়েছে।
              </h3>
              <p className="text  h4 mb-0">
                আপনার এক্টিভেশন কোডটি হলো &nbsp;&nbsp;
                <span className="h2 text-success">
                  <b>{props.data.data.ActivateCode}</b>
                </span>
              </p>
              <p className="h6 text-success p-2">
                আপনার টিভির নির্দেশনা অনুযায়ী এক্টিভেশন কোডটি ব্যবহার করুন।
              </p>
              <br />
              {/* <div className="btn btn-success">
                <form method="get" action="" className="validate-form">
                    <i className="fa fa-download" aria-hidden="true"></i>
                    &nbsp;&nbsp;Download
                </form>
            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.data.status == 2) {
    return (
      <>
        <div className="col-lg-12 col-md-12">
          <div className="card border-0 shadow rounded-lg py-4 text-start">
            <div className="card-body p-5">
              <h3 className="h3  text-success">
                আপনার টিভির রেজিস্ট্রেশন সফল হয়েছে।
              </h3>
              <p lassName="text h6 text-success">
                আপনি ইতিপূর্বে এই কোডটি সংগ্রহ করে ছিলেন।
              </p>
              <p className="text  h4 mb-0">
                এক্টিভেশনের সময় হলোঃ &nbsp;
                <span className="text-success">
                  {new Date(props.data.data.ActivationDate.date).toISOString().split('T')[0]}
                  &nbsp;
                  <br />
                </span>
                আপনার এক্টিভেশন কোডটি হলো &nbsp;&nbsp;
                <span className="h2 text-success">
                  <b>{props.data.data.ActivateCode}</b>
                </span>
              </p>
              <br />
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.data.status == 3) {
    return (
      <>
        <div className="col-lg-12 col-md-12">
          <div className="card border-0 shadow rounded-lg py-4 text-start">
            <div className="card-body p-5">
              <h3 className="h1  text-warning">দুঃখিত</h3>
              <br />
              <p className="text h4 text-success">
                টিভিটি অন্য নাম্বার দিয়ে ইতিপূর্বে রেজিস্ট্রেশন করা হয়েছে।{" "}
              </p>
              <br />
              <p className="text  h4 mb-0">
                এক্টিভেশন নাম্বারটি হলো &nbsp;&nbsp;
                <span className="text-success">
                  {props.data.data.CustomerNumber}
                </span>
              </p>
              <br />
              <p className="text h6  mb-0">
                এক্টিভেশনের সময় হলোঃ &nbsp;&nbsp;
                <span className="text-success">
                  {new Date(props.data.data.ActivationDate.date).toISOString().split('T')[0]}
                </span>
              </p>
              <br />
            </div>
          </div>
        </div>
      </>
    );
  }
  if (props.data.status == 0) {
    return (
      <>
        <div className="col-lg-12 col-md-12">
          <div className="card border-0 shadow rounded-lg py-4 text-start">
            <div className="card-body p-5">
              <h3 className="h3  text-warning">দুঃখিত</h3>
              <p className="text  h4 mb-0 text-success">
                আপনার এক্টিভেশন সফল হয়নি।
              </p>
              <p className="h6 text-error p-2">
                একটি নাম্বার দিয়ে একবছরে তিনবারের অধিক রেজিস্ট্রেশন করা যাবে না।
              </p>
              <br />
              {/* <div className="btn btn-success">
                <form method="get" action="" className="validate-form">
                    <i className="fa fa-download" aria-hidden="true"></i>
                    &nbsp;&nbsp;Download
                </form>
            </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default SuccessData;
