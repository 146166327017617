import React, { useState } from "react";
import { VerificationPage } from "./components/VerificationPage";
import OTPPage from "./components/OTPPage";
import PhonePage from "./components/PhonePage";
import { SinglePage } from "./components/SinglePage";

function App(props) {
  const [whichPage, setWhichPage] = useState(0);
  const [userPhone, setUserPhone] = useState(0);
  const [otpCode, setOTPCode] = useState(0);

  function handlePageSelect(index) {
    setWhichPage(index);
  }

  function handleUserPhone(index) {
    setUserPhone(index);
  }

  function setOtpCode(index) {
    setOTPCode(index);
  }

  return (
    <VerificationPage
      handlePageSelect={handlePageSelect}
      userPhone={userPhone}
      setActive={props.setActive}
      active={props.active}
    />
  );

  // return <SinglePage />;
  // return whichPage == 2 ? (
  //   <VerificationPage
  //     handlePageSelect={handlePageSelect}
  //     userPhone={userPhone}
  //     setActive={props.setActive}
  //     active={props.active}
  //   />
  // ) : whichPage == 1 ? (
  //   <OTPPage
  //     handlePageSelect={handlePageSelect}
  //     userPhone={userPhone}
  //     setActive={props.setActive}
  //     active={props.active}
  //     serverOTP={otpCode}
  //   />
  // ) : (
  //   <PhonePage
  //     handlePageSelect={handlePageSelect}
  //     handleUserPhone={handleUserPhone}
  //     setActive={props.setActive}
  //     active={props.active}
  //     setOtpCode={setOtpCode}
  //   />
  // );
}

export default App;
