import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import BounceLoader from "react-spinners/BounceLoader";

function Loader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<BounceLoader />}
      style={{ height: "100vh" }}
    >
      {children}
    </LoadingOverlay>
  );
}

export default Loader;
