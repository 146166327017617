import React from "react";
import TextField from "@mui/material/TextField";
import { Spinner } from "react-bootstrap";

export const FormInput = (props) => {
  return (
    <>
      <div className="col-lg-12 col-md-12">
        <div className=" card p-4">
          <form method="post" action="" className="validate-form">
            <span className="login100-form-title p-b-43">
              রেজিস্ট্রেশন ফর্ম
            </span>
            <div className="container my-2">
              <TextField
                id="outlined-basic"
                label="বার কোড প্রদান করুন"
                variant="outlined"
                value={props.barCode}
                onChange={props.handleBarCode}
                style={{ width: "100%" }}
                error={props.barError}
                helperText={props.barMessage}
              />
            </div>
            <div className="container my-2">
              <TextField
                id="outlined-basic"
                label="পিন কোড প্রদান করুন"
                value={props.pinCode}
                variant="outlined"
                onChange={props.handlePinCode}
                style={{ width: "100%", textTransform: "uppercase" }}
                error={props.pinError}
                maxlength="3"
              />
            </div>

            <div className="container my-2">
              <TextField
                id="outlined-basic"
                label="ফোন নাম্বার"
                variant="outlined"
                value={props.phoneNumber}
                onChange={props.handlePhoneNumber}
                style={{ width: "100%" }}
                error={props.phoneError}
              />
            </div>
            <div className="container-login100-form-btn p-2">
              <button
                className="login100-form-btn"
                type="submit"
                name="submit"
                disabled={props.active}
                onClick={props.handleSubmitData}
              >
                পাঠিয়ে দিন
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormInput;
