import React, { useState } from "react";
import App from "./App.js";
import Loader from "./Loader.js";
const Home = () => {
  const [active, setActive] = useState(false);

  return (
    <div>
      <Loader active={active}>
        <App
          style={{ height: "100vh" }}
          setActive={setActive}
          active={active}
        />
      </Loader>
    </div>
  );
};

export default Home;
